import React from "react";
import { Container, Form, Row, Button, Col } from "react-bootstrap";
import { BsPrinterFill } from "react-icons/bs";
import { useSearchParams } from "react-router-dom";
import moment from "moment";
import { ReactComponent as OrderSvg } from "./../assets/icons/Order.svg";
import { ReactComponent as ShipSvg } from "./../assets/icons/Ship.svg";
import { ReactComponent as SignSvg } from "./../assets/icons/Sign.svg";
import { ReactComponent as WarehouseSvg } from "./../assets/icons/Warehouse.svg";
import { ReactComponent as WaybillSvg } from "./../assets/icons/Waybill.svg";
import { ReactComponent as ArrowSvg } from "./../assets/icons/arrow.svg";

import "./track-page.css";

const TrackingPage = () => {
  const [searchParams] = useSearchParams();
  const [search, setSearch] = React.useState();
  const [data, setData] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [notFound, setNotFound] = React.useState(false);

  const onSearch = () => {
    if (search.length > 0) {
      window.location = `/tracking?waybill_number=${search}`;
    }
  };

  const getStepTitle = (status) => {
    switch (status) {
      case "ORDERCREATED":
        return "Prepare Order Form";

      case "WAYBILLCREATED":
        return "Received at Warehouse";

      case "WAYSTARTED":
        return "Ready to ship";

      case "WAYENDED":
        return "Arrived at Warehouse";

      case "SIGNED":
        return "Signed";
      case "COMMENT":
        return "";

      default:
        return "";
    }
  };

  const getStepIcon = (status) => {
    switch (status) {
      case "ORDERCREATED":
        return <OrderSvg className="svg" />;

      case "WAYBILLCREATED":
        return <WaybillSvg className="svg" />;

      case "WAYSTARTED":
        return <ShipSvg className="svg" />;
      case "WAYENDED":
        return <WarehouseSvg className="svg" />;

      case "SIGNED":
        return <SignSvg className="svg" />;
      case "COMMENT":
        return <SignSvg className="svg" />;

      default:
        return "";
    }
  };

  const getSubStepTitle = (status, stepData) => {
    switch (status) {
      case "ORDERCREATED":
        return `Your items have opened order form by our customer service`;

      case "WAYBILLCREATED":
        return `Your items have opened waybill voucher and received by our warehouse`;

      case "COMMENT":
        return stepData.content;

      case "WAYSTARTED":
        return `Your items [${stepData?.info?.issued_info?.qty}] Qty have succeed load our container and ready to shipping`;

      case "WAYENDED":
        return `You items [${stepData?.info?.issued_info?.qty}] Qty have arrived to ${stepData?.info?.branch?.name} warehouse`;

      case "SIGNED":
        return `Your items [${
          stepData?.info?.issued_info?.qty
        }] Qty have received by ${stepData?.info?.sign_info?.name} at ${moment(
          stepData.createdAt
        ).format("HH:mm")}`;

      default:
        return "";
    }
  };

  React.useEffect(() => {
    async function fetchData() {
      if (searchParams.get("waybill_number")) {
        try {
          setLoading(true);
          let response = await fetch(
            `${
              process.env.REACT_APP_BASE_URL
            }/frontend/get-waybill-tracking?waybill_number=${searchParams.get(
              "waybill_number"
            )}`
          );
          let data = await response.json();
          data.logs = data.logs.reverse();
          if (data.logs.find((e)=>e.status==="SIGNED")){
            data.logs=data.logs.filter((e)=>e.status!=="COMMENT");
          }
          setData(data);
          setLoading(false);
          setNotFound(false);
        } catch {
          setData(null);
          setLoading(false);
          setNotFound(true);
        }
      }
    }
    fetchData();
  }, [searchParams]);

  return (
    <div className="py-3">
      <section>
        <Container className="my-3">
          <div className="d-flex justify-content-between align-items-center">
            <h1>Tracking</h1>
            <Button variant="info" className="text-white d-sm-block d-none">
              <BsPrinterFill /> Print
            </Button>
          </div>

          <div>Container/transport document number</div>
          <Row>
            <Col md={4} xs={8}>
              <Form.Control
                className="me-auto"
                placeholder="Eg. EF123456"
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
            </Col>
            <Col md={4} xs={4}>
              <Button variant="info" className="text-white" onClick={onSearch}>
                {loading ? "Searching.." : "Search"}
              </Button>
            </Col>
          </Row>
          <Row>
            <Col md={4} xs={8}>
              <div>
                Container number is made of 4 letters and 7 digits. Transport
                document number consists of 9 characters.
              </div>
            </Col>
          </Row>
          {loading ? (
            <Container
              style={{ paddingTop: 20, paddingBottom: 20, textAlign: "center" }}
            >
              Loading
            </Container>
          ) : (
            <>
              {!notFound && data && (
                <div className="title-box">
                  <h3>Waybill Number : {data.waybillnumber}</h3>
                  <div class="info-box">
                    <div>
                      <h5>{data.sender.name}</h5>
                      <p>{data.createdBranch?.name}</p>
                    </div>
                    <div style={{ flex: 1 }}>
                      <ArrowSvg className="arrow-svg" />
                    </div>
                    <div>
                      <h5>{data.receiver.name}</h5>
                      <p>{data.destination?.name}</p>
                    </div>
                  </div>
                </div>
              )}
              <div className="way-info">
                {notFound && (
                  <Container
                    style={{
                      paddingTop: 20,
                      paddingBottom: 20,
                      textAlign: "center",
                    }}
                  >
                    <h4 style={{ color: "red" }}>Waybill Number Not Found</h4>
                  </Container>
                )}

                {!notFound && data && (
                  <Container className="pb-3">
                    <nav className="nav nav-pills flex-column">
                      {data.logs.map((step) => (
                        <a className="nav-link active" href="#">
                          <div className="nav-container">
                            <div className="left-info">
                              <span>
                                {moment(step.createdAt).format("MMM D")}
                              </span>
                              <br />
                              <span>
                                {moment(step.createdAt).format("HH:mm")}
                              </span>
                            </div>
                            <div className="icon">
                              {getStepIcon(step.status)}
                            </div>
                          </div>
                          <div className="right-info">
                            <h5>{getStepTitle(step.status)}</h5>
                            <p>{getSubStepTitle(step.status, step)}</p>
                          </div>
                        </a>
                      ))}
                    </nav>
                  </Container>
                )}
              </div>
            </>
          )}
        </Container>
      </section>
    </div>
  );
};

export default TrackingPage;
