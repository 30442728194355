import React from "react";
import "./home-page.css";
function TalkingGroup({ one = false }) {
  return (
    <div className="talking-box">
      <div className="talking">
        <div class="label">
          <p>
            We strongly support best practice sharing across our operations
            around the world and across various industrial sectors.
          </p>
        </div>
        <div className="bytalking">
          <img
            className="avatar"
            src="https://store.playstation.com/store/api/chihiro/00_09_000/container/BR/en/99/UP0334-CUSA02328_00-AV00000000000026/0/image?_version=00_09_000&platform=chihiro&bg_color=000000&opacity=100&w=720&h=720"
          />
          <div>
            <h6>Franncio Cheasea</h6>
            <p>By Louse Moterson</p>
          </div>
        </div>
      </div>
      {!one && (
        <div className="talking">
          <div class="label">
            <p>
              We strongly support best practice sharing across our operations
              around the world and across various industrial sectors.
            </p>
          </div>
          <div className="bytalking">
            <img
              className="avatar"
              src="https://store.playstation.com/store/api/chihiro/00_09_000/container/BR/en/99/UP0334-CUSA02328_00-AV00000000000026/0/image?_version=00_09_000&platform=chihiro&bg_color=000000&opacity=100&w=720&h=720"
            />
            <div>
              <h6>Franncio Cheasea</h6>
              <p>By Louse Moterson</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default TalkingGroup;
