import React from "react";
import { Card } from "react-bootstrap";
import "./home-page.css";
function BlogComponents({ images }) {
  return (
    <div className="blog-section">
      {images.map((e, index) => (
        <Card className="blog-item" key={`blog-index-${index}`}>
          <Card.Img variant="top" src={e} />
          <Card.Body>
            <Card.Title>Customized Fleet Technology Solutions</Card.Title>
            <p>
              all international station to guarantee that your load will safely
              reach without any delays.
            </p>
            <div className="blog-action">
              <img
                className="avatar"
                src="https://store.playstation.com/store/api/chihiro/00_09_000/container/BR/en/99/UP0334-CUSA02328_00-AV00000000000026/0/image?_version=00_09_000&platform=chihiro&bg_color=000000&opacity=100&w=720&h=720"
              />
              <p>By Louse Moterson</p>
            </div>
          </Card.Body>
        </Card>
      ))}
    </div>
  );
}

export default BlogComponents;
