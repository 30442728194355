import { Col, Container, Row, Button, Card } from "react-bootstrap";
import TrackingComponent from "../components/Home/TrackingComponent";
import Carousel from "nuka-carousel";
import {
  BsCursor,
  BsMailbox,
  BsPhone,
  BsFacebook,
  BsTwitter,
  BsLinkedin,
} from "react-icons/bs";

import DeliCar from "./../assets/imgs/deli_car.png";
import * as styles from "../styles/HomePageStyle.module.css";
import BlogComponents from "./BlogComponents";
import TalkingGroup from "./TalkingGroup";

import WarehouseImg from "./../assets/imgs/warehousing.jpg";
import OceanImg from "./../assets/imgs/oean.jpg";
import D2DImg from "./../assets/imgs/d2d.jpg";

import Image1 from "./../assets/imgs/1.jpg";
import Image2 from "./../assets/imgs/2.jpg";
import Image3 from "./../assets/imgs/3.jpg";
import Image4 from "./../assets/imgs/4.jpg";
import Image5 from "./../assets/imgs/5.jpg";
import Image6 from "./../assets/imgs/6.jpg";
import Image7 from "./../assets/imgs/7.jpg";
import Image8 from "./../assets/imgs/8.jpg";
import Image9 from "./../assets/imgs/9.jpg";

import "./home-page.css";

const HomePage = () => {
  return (
    <div>
      <section className={styles.content}>
        <div className={styles.backdrop}>
          <Container>
            <Row>
              <Col sm={12} md={6} className="my-3">
                <h1 className="text-white">
                  Call us your local integrator of logistics
                </h1>
                <p className="text-white">
                  We support your business with innovative supply chain
                  solutions by taking the complexity out of the shipping journey
                  and providing you a smoother logistics experience
                </p>
                <Button  style={{ color: "white",background:'#2557c8',border:"none" }}>
                  Explore More
                </Button>
              </Col>
              <Col md={6} sm={12} className="my-3">
                <TrackingComponent />
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      {/* side banner */}
      <section className="side-banner">
        <div>
          <h5>Call Our Support</h5>
          <p>24/7 Support Line : +959940669868</p>
        </div>
        <div>
          <h5>Our Location</h5>
          <p>
            No.H-7 ,MayKha Housing , Thingaungyun Township , Yangon Division
            ,Myanmar
          </p>
        </div>
        <div className="tb-container">
          <input
            type="text"
            placeholder="your email"
            className="form-control"
          />
          <Button>Send</Button>
        </div>
      </section>
      {/* side banner */}
      {/* services */}
      <section className="services">
        <div className="subtitle">Our Service</div>
        <h1 className="title">
          We provide full assistance in <br />
          freight and warehousing
        </h1>
        <div className="image-cards">
          <div>
            <img src={OceanImg} />
            <h3>Ocean Freight</h3>
            <p>
              Our Ocean Freight service provide transport General Cargo
              ,Machinery , Food and Chemicals products from China (Dongguang
              Port) to Myanmar (Yangon Port).
            </p>
          </div>
          <div>
            <img src={WarehouseImg} />
            <h3>Warehousing</h3>
            <p>
              Our Warehousing service support your purchase goods to store at
              our warehouse before they are shipped to another location for
              fulfillments.
            </p>
          </div>
          <div>
            <img src={D2DImg} />
            <h3>Door To Door Delivery</h3>
            <p>
              Our Door To Door Delivery Service transport your purchase product
              to your provided location (Within Yangon and Mandalay Area) using
              our delivery service.
            </p>
          </div>
        </div>
        <div className="text-container">
          <p>
            Our list of services does not end here. We’ll adapt to your
            particular needs.
          </p>
          <p>Get a Quote for Your Business</p>
        </div>
      </section>
      {/* services */}
      {/* video */}
      <section className="video-container">
        <div>
          <iframe
            src="https://www.youtube.com/embed/0tMsqhmenbs"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <div>
          <h1>Logistics Service System</h1>
          <p>
            Easy Freight Forwarding Logistics has developed the software for the
            whole logistics service as own and it is thus a more systematic
            service that including of shipping date and time, factory ‘s
            location, buyer’s product, Location of arrival, quantities and so
            on.
          </p>
        </div>
        <img className="deli-car" src={DeliCar} />
      </section>
      {/* video */}

      {/* inquiry */}
      <section className="inquiry-container">
        <div>
          <div className="form-card">
            <h1>Need to Make an Enquiry?</h1>
            <Row>
              <Col>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Your name"
                />
              </Col>
              <Col>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Affliation"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Email *"
                />
              </Col>
              <Col>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Number *"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Website *"
                />
              </Col>
              <Col>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Inquiry Department"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Topic *"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <textarea
                  className="form-control"
                  placeholder="Your Message"
                  rows={10}
                ></textarea>
              </Col>
            </Row>
            <Button className="inquiry-submit-btn">Submit Message</Button>
          </div>
        </div>
        <div>
          <div className="inquiry-right-info">
            <h6>GET IN TOUCH WITH US</h6>
            <h1>We take care about transportation for your business</h1>
            <p>
              Easyf has experience in handling the formalities and documentation
              required for your imports and exports. We work with all
              international station to guarantee that your load will safely
              reach without any delays.
            </p>
            <h4>China Warehouse</h4>
            <div class="icon-list">
              <div className="icon">
                <BsCursor />
              </div>
              <p className="desc">
                Dongguan City, Guangdong Province, Ranching Street, Baima
                Pioneer Road, No. 6 Goldman Sachs Logistics Park 1 Building 1
                116-117,
              </p>
            </div>
            <div class="icon-list">
              <div className="icon">
                <BsMailbox />
              </div>
              <p className="desc">marketing@easyfmm.com</p>
            </div>
            <div class="icon-list">
              <div className="icon">
                <BsPhone />
              </div>
              <p className="desc">Tel: 13316683535</p>
            </div>
          </div>
          <div className="social-list">
            <div>
              <BsFacebook /> <span>facebook</span>
            </div>
            <div>
              <BsTwitter /> <span>twitter</span>
            </div>
            <div>
              <BsLinkedin /> <span>linkedin</span>
            </div>
          </div>
        </div>
      </section>

      {/* inquiry */}

      {/* blog */}
      <section className="blog-section-container">
        <Carousel>
          <BlogComponents images={[Image1, Image2, Image3]} />
          <BlogComponents images={[Image4, Image5, Image6]} />
          <BlogComponents images={[Image7, Image8, Image9]} />
        </Carousel>
      </section>
      <section className="blog-section-container-mobile">
        <BlogComponents
          images={[
            Image1,
            Image2,
            Image3,
            Image4,
            Image5,
            Image6,
            Image7,
            Image8,
            Image9,
          ]}
        />
      </section>
      {/* blog */}

      {/* ACCOMPLISHMENTS */}
      <section className="accomplish">
        <div className="subtitle">ACCOMPLISHMENTS</div>
        <h1 className="title">
          Let the numbers speak for
          <br />
          themselves
        </h1>
        <div className="numbers-container">
          <div>
            <img src="https://moovit.foxthemes.me/wp-content/uploads/2019/08/10a.png" />
            <h1>5,7343</h1>
            <h2>Delivered packages</h2>
            <p>
              We strongly support best practice sharing across our operations
              around the world and across various industrial sectors.
            </p>
          </div>
          <div>
            <img src="https://moovit.foxthemes.me/wp-content/uploads/2019/08/11a.png" />
            <h1>123</h1>
            <h2>Countries covered</h2>
            <p>
              We strongly support best practice sharing across our operations
              around the world and across various industrial sectors.
            </p>
          </div>
          <div>
            <img src="https://moovit.foxthemes.me/wp-content/uploads/2019/08/12.png" />
            <h1>476</h1>
            <h2>Tons of goods</h2>
            <p>
              We strongly support best practice sharing across our operations
              around the world and across various industrial sectors.
            </p>
          </div>
        </div>
      </section>

      {/* talking */}
      <section className="talkings">
        <div className="two">
          <Carousel>
            <TalkingGroup />
            <TalkingGroup />
            <TalkingGroup />
          </Carousel>
        </div>
        <div className="one">
          <Carousel>
            <TalkingGroup one />
            <TalkingGroup one />
            <TalkingGroup one />
          </Carousel>
        </div>
      </section>

      {/* talking */}
    </div>
  );
};

export default HomePage;
