import React from "react";
import { Card, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const TrackingComponent = () => {

  
  const [search, setSearch] = React.useState();
 

  const onSearch = () => {
    if (search.length > 0) {
      window.location = `/tracking?waybill_number=${search}`;
    }
  };


  return (
    <Card>
      <Card.Body>
        <Card.Title>Tracking</Card.Title>
        <Form>
          <Form.Group className="my-3" controlId="form-tracking">
            <Form.Label>Waybill Number</Form.Label>
            <Form.Control type="text" placeholder="Eg. EF123456"  onChange={(e) => {
                  setSearch(e.target.value);
                }}/>
          </Form.Group>
          <Link to="/tracking">
            <Button  style={{ color: "white",border:'none',background:'#2557c8' }} onClick={onSearch}>
              Track
            </Button>
          </Link>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default TrackingComponent;
